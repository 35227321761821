var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mb-3"},[_c('v-col',{staticClass:"text-right"},[(_vm.parent.timestampDeleted)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error","large":"","to":{
          name: 'PageModeratorInstitutionClassroomDeleteAllStudents',
          params: {
            id: _vm.parent.id,
            me: _vm.parent,
            parentId: _vm.grandParent.id,
            parent: _vm.grandParent,
          },
        }}},[_vm._v(" Delete All ")]):_vm._e(),(_vm.parent.isActive)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","large":"","to":{
          name: 'PageModeratorInstitutionClassroomStudentTransfer',
          params: {
            parentId: _vm.parent.id,
            parent: _vm.parent,
            grandParentId: _vm.grandParent.id,
            grandParent: _vm.grandParent,
          },
        }}},[_vm._v(" + Import Students ")]):_vm._e(),(_vm.parent.isActive)?_c('v-btn',{attrs:{"color":"primary","large":"","to":{
          name: 'PageModeratorInstitutionClassroomStudentImport',
          params: {
            parentId: _vm.parent.id,
            parent: _vm.parent,
            grandParentId: _vm.grandParent.id,
            grandParent: _vm.grandParent,
          },
        }}},[_vm._v(" + Add Students ")]):_vm._e()],1)],1),_c('ShareLink',{attrs:{"type":"institution","id":_vm.grandParent.id}}),_c('v-tabs',{attrs:{"next-icon":"mdi-arrow-right-bold-box-outline","prev-icon":"mdi-arrow-left-bold-box-outline","show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(item){return _c('v-tab',{key:item.key,attrs:{"href":("#" + (item.key))}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:"active",staticClass:"px-1 py-2",attrs:{"value":"active"}},[_c('BreadBrowseTable',{attrs:{"role":"Moderator","model":_vm.model,"url":((_vm.$api.servers.sso) + "/api/v1/en/moderator/institution/" + (_vm.grandParent.id) + "/classroom/" + (_vm.parent.id) + "/student"),"isSearchable":true,"parent":_vm.parent,"modelParent":_vm.modelParent,"grandParent":_vm.grandParent,"modelGrandParent":_vm.modelGrandParent}})],1),_c('v-tab-item',{key:"trash",staticClass:"px-1 py-2",attrs:{"value":"trash"}},[_c('BreadBrowseTable',{attrs:{"role":"Moderator","model":_vm.model,"url":((_vm.$api.servers.sso) + "/api/v1/en/moderator/institution/" + (_vm.grandParent.id) + "/classroom/" + (_vm.parent.id) + "/student/trashed"),"isSearchable":true,"parent":_vm.parent,"modelParent":_vm.modelParent,"grandParent":_vm.grandParent,"modelGrandParent":_vm.modelGrandParent}})],1),_c('v-tab-item',{key:"inactive",staticClass:"px-1 py-2",attrs:{"value":"inactive"}},[_c('BreadBrowseTable',{attrs:{"role":"Moderator","model":_vm.model2,"url":((_vm.$api.servers.sso) + "/api/v1/en/moderator/institution/" + (_vm.grandParent.id) + "/classroom/" + (_vm.parent.id) + "/student/inactive"),"isSearchable":true,"parent":_vm.parent,"modelParent":_vm.modelParent,"grandParent":_vm.grandParent,"modelGrandParent":_vm.modelGrandParent}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }