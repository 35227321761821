<template>
  <div>
    <!-- BOC:[header] -->
    <v-row class="mb-3">
      <v-col class="text-right">
        <v-btn
          color="error"
          large
          v-if="parent.timestampDeleted"
          class="mr-2"
          :to="{
            name: 'PageModeratorInstitutionClassroomDeleteAllStudents',
            params: {
              id: parent.id,
              me: parent,
              parentId: grandParent.id,
              parent: grandParent,
            },
          }"
        >
          Delete All
        </v-btn>
        <v-btn
         v-if="parent.isActive"
          color="primary"
          large
          class="mr-2"
          :to="{
            name: 'PageModeratorInstitutionClassroomStudentTransfer',
            params: {
              parentId: parent.id,
              parent: parent,
              grandParentId: grandParent.id,
              grandParent: grandParent,
            },
          }"
        >
          + Import Students
        </v-btn>
        <v-btn
          v-if="parent.isActive"
          color="primary"
          large
          :to="{
            name: 'PageModeratorInstitutionClassroomStudentImport',
            params: {
              parentId: parent.id,
              parent: parent,
              grandParentId: grandParent.id,
              grandParent: grandParent,
            },
          }"
        >
          + Add Students
        </v-btn>
      </v-col>
    </v-row>
    <!-- EOC -->
    <!-- BOC:[game link] -->
    <ShareLink type="institution" :id="grandParent.id" />
    <!-- EOC -->
    <!-- BOC:[tabs] -->
    <v-tabs
      v-model="tab"
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
    >
      <v-tab v-for="item in tabs" :key="item.key" :href="`#${item.key}`">
        {{ item.label }}
      </v-tab>
    </v-tabs>
    <!-- EOC -->
    <!-- BOC:[tab] -->
    <v-tabs-items v-model="tab">
      <!-- BOC:[model] -->
      <v-tab-item key="active" value="active" class="px-1 py-2">
        <!-- BOC:[table] -->
        <BreadBrowseTable
          role="Moderator"
          :model="model"
          :url="`${$api.servers.sso}/api/v1/en/moderator/institution/${grandParent.id}/classroom/${parent.id}/student`"
          :isSearchable="true"
          :parent="parent"
          :modelParent="modelParent"
          :grandParent="grandParent"
          :modelGrandParent="modelGrandParent"
        ></BreadBrowseTable>
        <!-- EOC -->
      </v-tab-item>
      <!-- EOC -->
      <!-- BOC:[model] -->
      <v-tab-item key="trash" value="trash" class="px-1 py-2">
        <!-- BOC:[table] -->
        <BreadBrowseTable
          role="Moderator"
          :model="model"
          :url="`${$api.servers.sso}/api/v1/en/moderator/institution/${grandParent.id}/classroom/${parent.id}/student/trashed`"
          :isSearchable="true"
          :parent="parent"
          :modelParent="modelParent"
          :grandParent="grandParent"
          :modelGrandParent="modelGrandParent"
        ></BreadBrowseTable>
        <!-- EOC -->
      </v-tab-item>
      <v-tab-item key="inactive" value="inactive" class="px-1 py-2">
        <!-- BOC:[table] -->
        <BreadBrowseTable
          role="Moderator"
          :model="model2"
          :url="`${$api.servers.sso}/api/v1/en/moderator/institution/${grandParent.id}/classroom/${parent.id}/student/inactive`"
          :isSearchable="true"
          :parent="parent"
          :modelParent="modelParent"
          :grandParent="grandParent"
          :modelGrandParent="modelGrandParent"
        ></BreadBrowseTable>
        <!-- EOC -->
      </v-tab-item>
      <!-- EOC -->
    </v-tabs-items>
    <!-- EOC -->
  </div>
</template>

<script>
//BOC:[model]
import modelGrandParent from "@/models/items/institution";
import modelParent from "@/models/items/institutionClassroom";
import model2 from "@/models/items/institutionStudentInactive";
import model from "@/models/items/institutionStudent";
//EOC
//BOC:[table]
import BreadBrowseTable from "@/components/Bread/BreadBrowseTable";
//EOC
import { mapState } from "vuex";
import ShareLink from "@/components/Bread/Dashboard/ShareLink.vue";
export default {
  components: {
    //BOC:[table]
    BreadBrowseTable,
    ShareLink,
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  props: ["role", "parent", "grandParent"],
  data: () => ({
       //BOC:[tabs]
       tab:null,
      tabs:[
        {
          key:'active',
          label:'Active',
        },
        {
          key:'inactive',
          label:'Inactive',
        },
        {
          key:'trash',
          label:'Trash',
        },
      ],
      //EOC
    //BOC:[model]
    modelParent: modelParent,
    model2:model2,
    modelGrandParent: modelGrandParent,
    model: model,
    //EOC
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>